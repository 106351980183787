<template>
  <div class="promoting">
    <header>
      <top-title
      title="Word of Mouth Marketing"
      subTitle="口碑宣傳">
      </top-title>
    </header>
    <page-main-body
      title="何謂口碑行銷？"
      subTitle="What is word of mouth marketing?"
      paragraph1="口碑行銷指的是運用新聞、論壇、部落客、意見領袖人物( KOL )，通過文字、影音、圖片等方式傳達有關企業品牌產品、服務訊息，從而讓目標客戶加深地了解企業的產品服務，最終達到企業宣傳的品牌目的。">
    </page-main-body>
    <div class="container">
      <h2>服務項目</h2>
      <div class="serviceTopics">
        <div @click="goto('news')" class="ball red">媒體新聞稿</div>
        <div @click="goto('controll')" class="ball blue">論壇操作</div>
        <div @click="goto('blog')" class="ball red">部落客</div>
        <div @click="goto('leaderKOL')" class="ball blue">Comment<br>留言數</div>
      </div>
      <div class="topic">
        <h2 class="title red">媒體新聞稿</h2>
        <div class="img news"></div>
        <p>新聞媒體稿指的是利用新聞傳播為企業、品牌等做宣傳推廣的一種行銷方式。新聞推廣模式非常有利於引導市場消費，在較短時間內快速提升產品的知名度，塑造品牌的美譽度和公信力。新聞稿推廣成為很多企業打造品牌，擴大品牌影響力，提高品牌公信力的重要手段之一</p>
        <h2 style="text-align:left">新聞稿行銷優勢</h2>
        <ol>
          <li> 讓您企業產品、活動、新聞訊息分秒之間高效傳播出去</li>
          <li>Google、Yahoo 等入口網站搜尋到相關新聞訊息</li>
          <li>以媒體角度、口吻提升品牌知名度與客戶信任感</li>
          <li>快速提升網站 SEO 效果</li>
          <li>新聞報導二次利用: 把媒體報導放到官網,企業 DM、宣傳處，   再次提高客戶認可及品牌知名度，對於企業內部徵才也有指標性口碑。</li>
          <li>可作為企業公關 : 快速、精準、高效傳播正面訊息</li>
          <li>低成本大曝光 : 相對於傳統媒體新聞，數位新聞媒體推廣成本較低且有效且永久存放於網路搜尋內。</li>
        </ol>
      </div>

      <div class="topic">
        <h2 class="title blue">論壇操作</h2>
        <div class="img controll"></div>
        <p>論壇操作討論是品牌運用網路各大討論區這種社區交流平台，通過文字、圖片、影片方式發布有關產品與企業訊息，從而讓這些目標客群加深了解企業產品與服務。</p>
        <h2 style="text-align:left">論壇操作優勢</h2>
        <ol>
          <li>覆蓋面廣、曝光力度強，論壇資源與討論受眾非常眾多</li>
          <li>論壇操作可採用軟性議題討論，引發群眾對該主題的興趣</li>
          <li>文章中植入討論的產品，可直接導流效果，或提高品牌知名度</li>
          <li>論壇操作可帶上產品連結，非常有利于 SEO 優化</li>
        </ol>
      </div>

      <div class="topic">
        <h2 class="title red">部落格</h2>
        <div class="img blog"></div>
        <p>企業的品牌或服務除了廣告投投放外，需藉由一系列的素人部落格來豐富口碑，部落客以試用口吻撰寫 500 - 1000字搭配 5 - 10 張以上不等之照片，傳達該商品與服務的內容。</p>
        <h2 style="text-align:left">部落格操作優勢</h2>
        <ol>
          <li>有溫度的傳達企業品牌與服務的真實內容</li>
          <li>文章接可放入連結，直接性導流也有助於搜尋</li>
          <li>累積網路搜尋文章量作為口碑堆疊</li>
          <li>文章累積量多亦有助於 SEO</li>
        </ol>
      </div>

      <div class="topic">
        <h2 class="title blue">意見領袖人物 KOL</h2>
        <div class="img leaderKOL"></div>
        <p>KOL 中文簡稱為 : 網紅。此意見領袖有分為個人或主題頻道 ex.流行人 顧名思義，運用網紅鐵粉的力量操作或直接性導流到商品或服務頁面，網紅可透過文字、圖片、影片甚至直播的方式傳達該商品與服務。</p>
        <h2 style="text-align:left">KOL 個人或主題操作優勢</h2>
        <ol>
          <li>以往品牌多找明星藝人來代言或背書，在高成本之下，相對性短暫化的網紅推薦與加持可降低代言成本。</li>
          <li>網紅多半以社群平台做發布管道，企業可請求網紅批准為廣告主，用網紅之名義發佈廣告</li>
          <li>主題頻道扮演的力量為第三方口碑，好的商品不是企業品牌自己說，而是靠著他人的力量替品牌說，更加深目標群眾對該商品的好感度</li>
        </ol>
      </div>
    </div>
    <footer-view style="margin-top:50px"/>
  </div>
</template>

<script>
import topTitle from "@/components/topTitle.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import footerView from "@/components/footerView.vue";

export default {
  name: "promoting",
  components: {
    topTitle,
    pageMainBody,
    footerView
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$parent.inPage = true;
  },
  destroyed() {
    this.$parent.inPage = false;
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {},
    goto(topic) {
      var target = $("." + topic).offset().top;
      $("html, body").animate({ scrollTop: target - 150 }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

header {
  background-image: url("../../assets/image/service/promoting/topBanner.jpg");
}
.promoting {
  display: flex;
  flex-direction: column;
  align-items: center;
  .container {
    width: 100vw * 2 / 3;
    @media #{$tablet} {
      width: 90vw;
    }
    .serviceTopics {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 80px 0;
      width: 100%;
      @media #{$phone} {
        margin: 20px 0;
      }
      .ball {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font(small);
        color: #fff;
        white-space: nowrap;
        $ballSize: 160px;
        width: $ballSize;
        height: $ballSize;
        border-radius: 100%;
        margin: 5px auto;
        transform: scale(1);
        transition: 0.3s;
        &.red {
          background-color: $mainRed;
        }
        &.blue {
          background-color: $mainBlue;
        }
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    ol li {
      text-align: left;
      padding-right: 3%;
    }
    .topic {
      display: flex;
      flex-direction: column;
      h2 {
        padding-left: 1%;
        margin: 2% 0;
      }
      p {
        text-align: left;
        padding: 0 3%;
      }
      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        &::before,
        &::after {
          content: "";
          display: block;
          $dotSize: 12px;
          width: $dotSize;
          height: $dotSize;
          border-radius: 100%;
          margin: auto 10px;
        }
        &.red::before,
        &.red::after {
          background-color: $mainRed;
        }
        &.blue::before,
        &.blue::after {
          background-color: $mainBlue;
        }
      }
      .img {
        position: relative;
        background-size: cover;
        align-self: center;
        width: 63vw * 0.5;
        height: 43vw * 0.5;
        @media #{$tablet} {
          width: 63vw * 80/63;
          height: 43vw * 80/63;
        }
        @media #{$phone} {
          width: 63vw * 100/63;
          height: 43vw * 100/63;
        }
        &.news {
          background-image: url("../../assets/image/service/promoting/news.jpg");
        }
        &.controll {
          background-image: url("../../assets/image/service/promoting/controll.jpg");
        }
        &.blog {
          background-image: url("../../assets/image/service/promoting/blog.jpg");
        }
        &.leaderKOL {
          background-image: url("../../assets/image/service/promoting/leaderKOL.jpg");
        }
      }
    }
  }
}
</style>
